import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import Subscribe from '../components/subscribe/Subscribe';
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Organizational Challenges</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>Organizational Challenges</PostFullTitle>
          </PostFullHeader>
          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>Building an effective organization is a constant challenge for any senior leadership team. Below, we&apos;ve summarized some of the challenges you might be facing within your organization, do they resonate with you?</p>
              <h2>CEO</h2>
              <p>As a CEO, you&apos;ll be looking for ways to obtain more predictable results, understand the impact of any organization design decision on your investors and key stakeholders but also gain a clearer understanding of why things take so long to get done, what is preventing changes from being delivered faster?</p>
              <h3>CFO</h3>
              <p>Being able to keep track of budgets and where money is spent is a fundamental challenge faced by any person in the CFO role. CFOs are often trying to understand why cost go up every year and attempting to uncover ways to align value to money spent.</p>
              <h3>COO</h3>
              <p>The COO is mostly looking for ways to increase the overall operational efficiency of the organization. Is money being spent in the right way to deliver effective value. There is a constant need to explore ways to achieve better alignment and standardization to use available funds as effectively as possible.</p>
              <h3>CTO/CIO</h3>
              <p></p>
              <h3>CPO</h3>
              <p></p>
              <h3>CHRO</h3>
              <p></p>
            </div>
          </PostFullContent>
        </article>
        {/* The big email subscribe modal content */}
        {config.showSubscribe && <Subscribe title="What have we missed?" />}
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
